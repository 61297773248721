import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login";
import Home from "../views/Home";
import Welcome from "../views/Welcome";
import User from "../views/user/User";
import Rights from "../views/permission/Rights";
import Roles from "../views/permission/Roles";
import ArticleSystem from "../views/article/ArticleSystem";
import ArticleAddOrEdit from "../views/article/ArticleAddOrEdit";
import PictureSort from "../views/picture/PictureCategory";
import PictureList from "../views/picture/PictureList";
import SystemConfig from "../views/config/SystemConfig";
import LinkConfig from "../views/config/LinkConfig";
import WebLog from "../views/log/WebLog";
import Category from "../views/article/ArticleCategory";
import Picture from "../views/picture/Picture";
import ArticleTag from "../views/article/ArticleTag";
// import Home from '../views/Home.vue'

Vue.use(VueRouter)


const router = new VueRouter({
  routes: [
    {path: '/', redirect: '/welcome'},
    {path: '/login', component: Login},
    {
      path: '/home', component: Home,
      redirect: '/welcome',
      children: [
        {path: '/welcome', component: Welcome},
        {path: '/user/list', component: User},
        {path: '/permission/rights', component: Rights},
        {path: '/permission/roles', component: Roles},
        {path: '/article/list', component: ArticleSystem},
        {path: '/article/category', component: Category},
        {path: '/article/tag', component: ArticleTag},
        {path: '/article/add', component: ArticleAddOrEdit},
        {path: '/article/:id/edit', component: ArticleAddOrEdit},
        {path: '/picture/category', component: PictureSort},
        {path: '/picture/list', component: Picture},
        {path: '/picture/list/:id', component: PictureList},
        {path: '/config/system', component: SystemConfig},
        {path: '/config/link', component: LinkConfig},
        {path: '/log/list', component: WebLog}
      ]
    }
  ]
})
//挂载路由导航守卫
router.beforeEach((to, form, next) => {
  //to 将要访问的路径
  //from 代表从哪个路径跳转过来
  //next是一个函数，表示放行
  //next() 放行， next('/')强制跳转
  //获取token
  // const tokenStr = window.localStorage.getItem("token")
  // //如何访问登录页，但是带了token,则重定向到欢迎页
  // if (to.path === '/login' && tokenStr !== '' && tokenStr !== "null") return next('/welcome')
  // //如果没带token
  // if ((to.path === '/login' && tokenStr === '') || (to.path === '/login' && tokenStr === "null")) return next()
  // //带了token
  // next()
  const tokenStr = window.localStorage.getItem('token')
  if (to.path === '/login') return next()
  if (!tokenStr) {
    return next('/login')
  }
  next()
})
export default router

