<template>
  <div>welcome</div>
</template>

<script>
  export default {
    name: "Welcome"
  }
</script>

<style lang="less" scoped>

</style>
