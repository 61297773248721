<template>
  <div>
    <!--表单-->
    <div class="m-content">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="标题" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="summary">
          <el-input v-model="ruleForm.summary"></el-input>
        </el-form-item>
        <el-form-item label="标题图">
          <template slot-scope="scope">
            <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :headers="headerData"
                :on-success="uploadSuccess"
                :data="pictureVO"
            >
              <img v-if="ruleForm.picUrl" :src="ruleForm.picUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </template>
        </el-form-item>
        <!--        <el-form-item label="推荐等级" prop="level">-->
        <!--          <el-select v-model="ruleForm.level" placeholder="请选择" style="width: 200px">-->
        <!--            <el-option-->
        <!--                v-for="item in options"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-form-item label="所属分类" prop="categoryValue">
          <el-select v-model="ruleForm.categoryValue" placeholder="请选择" style="width: 200px">
            <el-option
                v-for="articleCategory in categoryOptions"
                :key="articleCategory.categoryValue"
                :label="articleCategory.categoryName"
                :value="articleCategory.categoryValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属标签" prop="tagValue">
          <el-select v-model="ruleForm.tagValue" multiple placeholder="请选择">
            <el-option
                v-for="item in tagOptions"
                :key="item.tagValue"
                :label="item.tagName"
                :value="item.tagValue">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推荐等级" prop="level">
          <el-select v-model="ruleForm.level" placeholder="请选择" style="width: 200px">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否发布" prop="published">
          <el-select v-model="ruleForm.published" placeholder="请选择">
            <el-option
                v-for="item in publishedOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <mavon-editor v-model="ruleForm.content" ref="md"
                        @imgAdd="$imgAdd" @imgDel="$imgDel"></mavon-editor>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  name: "ArticleEdit",
  data() {
    return {
      //表单对象
      ruleForm: {
        id: '',
        title: '',
        summary: '',
        content: '',
        picUrl: null,
        level: '',
        published: 1,
        categoryValue: '',
        tagValue: [],
        author: ''
      },
      //检验规则
      rules: {
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'},
          {min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur'}
        ],
        summary: [
          {required: true, message: '请输入简介', trigger: 'blur'}
        ],
        content: [
          {required: true, message: '请输入内容', trigger: 'blur'}
        ],
        level: [
          {required: true, message: '请选择等级', trigger: 'change'}
        ],
        published: [
          {
            required: true, message: '请选择发布状态', trigger: 'change'
          }
        ]
      },
      //请求头参数
      headerData: {
        'X-Access-Token': window.localStorage.getItem('token')
      },
      //上传路径
      uploadUrl: 'https://www.coffeeo.cn/file-api/picture',
      //推荐等级选项 TODO 字典
      options: [{
        value: 1,
        label: '一级推荐'
      }, {
        value: 2,
        label: '二级推荐'
      }, {
        value: 3,
        label: '三级推荐'
      }, {
        value: 4,
        label: '四级推荐'
      }],
      //分类等级 TODO 字典
      categoryOptions: [],
      //标签
      tagOptions: [],
      //是否发布
      publishedOptions: [
        {
          value: 0,
          label: '下架'
        },
        {
          value: 1,
          label: '发布'
        }
      ],
      //图片上传参数vo
      pictureVO: {
        categoryId: '',
        categoryCode: '',
        storageType: ''
      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid && this.ruleForm.id) {
          const _this = this
          this.$http.put('/system-api/article', this.ruleForm).then(res => {
            this.$message.success(res.data.message)
            _this.$router.push('/article/list')
          })
        } else if (valid) {
          const _this = this
          this.ruleForm.author = window.localStorage.getItem('nickName')
          this.$http.post('/system-api/article', this.ruleForm).then(res => {
            this.$message.success(res.data.message)
            _this.$router.push('/article/list')
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //md编辑器中的上传图片
    $imgAdd(pos, $file) {
      // 第一步.将图片上传到服务器.
      let formdata = new FormData();
      formdata.append('file', $file);
      formdata.append('categoryId', this.pictureVO.categoryId)
      formdata.append('categoryCode', this.pictureVO.categoryCode)
      formdata.append('storageType', this.pictureVO.storageType)
      this.$http({
        url: 'https://www.coffeeo.cn/file-api/picture',
        method: 'post',
        data: formdata,
        headers: {"X-Access-Token": window.localStorage.getItem("token"), 'Content-Type': 'multipart/form-data'},
      }).then((res) => {
        if (res.data.code !== 200) {
          return this.$message.error(res.data.message)
        }
        this.$message.success(res.data.message)
        // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
        // $vm.$img2Url 详情见本页末尾
        const picUrl = res.data.result[0].picUrl;
        this.$refs.md.$img2Url(pos, picUrl);
      })
    },
    //md编辑器中的删除图片
    $imgDel(pos) {
      const param = {
        picUrl: pos[0]
      }
      //this.$http.delete('/file-api/file/picture/oss', {params: param})
    },
    //上传成功回调函数
    uploadSuccess(response) {
      this.ruleForm.picUrl = response.result[0].picUrl
    },
    //获取文章详情
    getArticleDetail() {
      const articleId = this.$route.params.id
      const _this = this
      if (articleId) {
        this.$http.get('/system-api/article/' + articleId).then(res => {
          _this.ruleForm = res.data.result
        })
      }
    },
    //获取文章分类
    getArticleCategory() {
      this.$http.get('/system-api/article/category').then(res => {
        this.categoryOptions = res.data.result.records
        //console.log(this.sortOptions)
      })
    },
    getArticleTag() {
      this.$http.get('/system-api/article/tag?pageSize=20').then(res => {
        this.tagOptions = res.data.result.records
      })
    },
    getConfig() {
      this.$http.get('/system-api/picture/config', {
        params: {
          categoryId: null
        }
      }).then(res => {
        this.pictureVO = res.data.result
        console.log(this.pictureVO)
      })
    }
  },
  created() {
    this.getArticleDetail()
    this.getArticleCategory()
    this.getArticleTag()
    this.getConfig()
  }
}
</script>

<style lang="less" scoped>
.m-content {
  text-align: left;
}

/*.avatar-uploader .el-upload {*/
/*  border: 1px dashed #d73a49;*/
/*  border-radius: 6px;*/
/*  cursor: pointer;*/
/*  position: relative;*/
/*  overflow: hidden;*/
/*}*/

/*.avatar-uploader .el-upload:hover {*/
/*  border-color: #d73a49;*/
/*}*/

.avatar-uploader-icon {
  font-size: 28px;
  color: #d73a49;
  width: 200px;
  height: 130px;
  line-height: 130px;
  text-align: center;
}

.el-icon-plus {
  border: 2px dotted;
}

.avatar {
  width: 200px;
  height: 130px;
  display: block;
}
</style>
