<template>
  <el-container class="home_container">
    <!--头部区域-->
    <el-header>
      <div>
        <img :src="avatar" class="my_avatar" alt="你的头像"/>
        <span>咖啡屋后台管理系统</span>
      </div>
      <span>欢迎您,{{this.nickName}}</span>
      <el-button @click="logout" type="info">退出</el-button>
    </el-header>
    <!--页面主体区域-->
    <el-container>
      <!--侧边栏-->
      <el-aside :width="isCollapsed ?'64px':'200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <el-menu
          background-color="#373c41"
          text-color="#fff"
          active-text-color="#409BFF"
          :unique-opened="true"
          :collapse="isCollapsed"
          :collapse-transition="false"
          :router="true"
          :default-active="activePath"
        >
          <!--一级菜单-->
          <el-submenu :index="item.id" v-for="item in menuList" :key="item.id">
            <!--一级菜单的模板区域-->
            <template slot="title">
              <!--图标-->
              <i :class="item.icon"></i>
              <!--文本-->
              <span>{{item.name}}</span>
            </template>
            <!--二级菜单-->
            <el-menu-item :index="subItem.component" v-for="subItem in item.children" :key="subItem.id"
                          @click="saveNavState(subItem.component)">
              <!--图标-->
              <i :class="subItem.icon"></i>
              <!--文本-->
              <span>{{subItem.name}}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!--右侧主体区域-->
      <el-main>
        <!--路由占位符-->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  export default {
    name: "Home",

    data() {
      return {
        //左侧菜单数据
        menuList: [],
        //是否折叠
        isCollapsed: false,
        //被激活的连接地址
        activePath: '',
        nickName: '',
        avatar: ''
      }
    },

    //生命周期函数，组件创建时会调用
    created() {
      this.getMenuList()
      this.activePath = window.localStorage.getItem("activePath")
      this.nickName = window.localStorage.getItem("nickName")
      if (window.localStorage.getItem("avatar") === null) {
        this.avatar = '../assets/default_avatar.jpg'
      } else {
        this.avatar = window.localStorage.getItem("avatar")
      }
    },

    methods: {
      logout() {
        this.$http.get('/auth-api/security/logout').then(res => {
          if (res.data.code !== 200) {
            this.$message.error(res.data.message)
          }
          window.localStorage.clear()
          //window.localStorage.setItem("token", '')
          //window.localStorage.setItem('nickName', '')
          this.$message.success(res.data.message)
          this.$router.push('/login')
        })
      },
      //获取所有的菜单
      getMenuList() {
        const {data: res} = this.$http.get('/auth-api/permission/tree').then(res => {
          if (res.data.code === 200) {
            this.menuList = res.data.result
          } else {
            return this.$message.error(res.data.message)
          }
        })
      },
      //点击按钮切换菜单的折叠与展开
      toggleCollapse() {
        this.isCollapsed = !this.isCollapsed
        toWhide = "64px"
      },
      //保存连接的激活状态
      saveNavState(activePath) {
        window.localStorage.setItem("activePath", activePath)
        this.activePath = activePath
      }
    }
  }
</script>

<style lang="less" scoped>
  .home_container {
    height: 100%;
  }

  .el-header {
    background-color: #373c41;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
    color: #FFF;
    font-size: 20px;

    > div {
      display: flex;
      align-items: center;

      span {
        margin-left: 15px;
      }
    }
  }

  .el-aside {
    background-color: #373c41;
  }

  .el-main {
    background-color: #eaedf2;
  }

  .iconfont {
    margin-right: 10px;
  }

  .el-menu {
    border-right: 0;
  }

  .toggle-button {
    background-color: #4A5064;
    font-size: 10px;
    line-height: 24px;
    color: #fffdef;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
  }

  .my_avatar {
    height: 50px;
    width: 50px;
  }
</style>
