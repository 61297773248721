import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store(
  {
    state: {
      token: localStorage.getItem("token"),
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
    },
    mutations: {
      //set
      SET_TOKEN: (state, token) => {
        state.token = token
      },
      SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
      },
      REMOVE_INFO: (state => {
        state.token = ''
        state.userInfo = {}
        localStorage.clear()
      })
    },
    getters: {
      //get
      getUser: state => {
        return state.userInfo
      },
      getToken: state => {
        return state.token
      }
    },
    actions: {},
    modules: {}
  }
)
