<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>文章管理</el-breadcrumb-item>
      <el-breadcrumb-item>文章标签</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <!--搜索框与添加区域-->
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入内容" v-model="queryInfo.tagName" :clearable="true" @clear="list">
            <el-button slot="append" icon="el-icon-search" @click="list"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible=true">添加标签</el-button>
        </el-col>
      </el-row>
    </el-card>
    <!--列表区域-->
    <el-table :data="articleTags"
              border stripe
              style="margin-top: 10px"
              :header-cell-style="{textAlign: 'center'}"
              :cell-style="{ textAlign: 'center' }"
    >
      <el-table-column type="index"></el-table-column>
      <el-table-column label="标签名" prop="tagName"></el-table-column>
      <el-table-column label="标签值" prop="tagValue"></el-table-column>
      <el-table-column label="创建时间" prop="createTime"></el-table-column>
      <el-table-column label="更新时间" prop="updateTime"></el-table-column>
      <el-table-column label="操作" width="300px">
        <template slot-scope="scope">
          <!--修改-->
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">修改
          </el-button>
          <!--删除-->
          <el-button type="danger" icon="el-icon-delete" size="mini"
                     @click="removeTag(scope.row.id)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--添加表单对话框-->
    <el-dialog
      title="添加标签"
      :visible.sync="addDialogVisible"
      width="30%"
      @close="addDialogClosed">
      <!--添加表单-->
      <el-form :label-position="left" label-width="80px" ref="addFormRef" :model="articleTagVO"
               :rules="articleTagVORule"
               :visible.sync="addDialogVisible">
        <el-form-item label="标签名" prop="tagName">
          <el-input v-model="articleTagVO.tagName"></el-input>
        </el-form-item>
        <el-form-item label="标签值" prop="tagValue">
          <el-input v-model="articleTagVO.tagValue"></el-input>
        </el-form-item>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="save">确 定
          </el-button>
        </span>
    </el-dialog>
    <!--编辑表单对话框-->
    <el-dialog
      title="编辑标签"
      :visible.sync="editDialogVisible"
      width="30%"
      @close="editDialogClosed">
      <!--添加表单-->
      <el-form :label-position="left" label-width="80px" ref="editFormRef" :model="editForm"
               :rules="editArticleTagVORule"
               :visible.sync="editDialogVisible">
        <el-form-item label="标签名" prop="tagName">
          <el-input v-model="editForm.tagName"></el-input>
        </el-form-item>
        <el-form-item label="标签值" prop="tagValue">
          <el-input v-model="editForm.tagValue" disabled></el-input>
        </el-form-item>
      </el-form>
      <!--底部区域-->
      <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="edit">确 定
          </el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "ArticleTag",
    data() {
      return {
        queryInfo: {
          tagName: '',
          pageNo: 1,
          pageSize: 10
        },
        articleTagVO: {
          tagName: '',
          tagValue: ''
        },
        editForm: {},
        articleTagVORule: {
          tagName: [
            {required: true, message: '请输入标签名', trigger: 'blur'}
          ],
          tagValue: [
            {required: true, message: '请输入标签值', trigger: 'blur'}
          ],
        },
        editArticleTagVORule: {
          tagName: [
            {required: true, message: '请输入标签名', trigger: 'blur'}
          ],
          tagValue: [
            {required: true, message: '请输入标签值', trigger: 'blur'}
          ],
        },
        //控制添加用户对话框的显示与隐藏
        addDialogVisible: false,
        editDialogVisible: false,
        articleTags: [],
        total: 0
      }
    },
    created() {
      this.list()
    },
    methods: {
      list() {
        this.$http.get('/system-api/article/tag', {params: this.queryInfo}).then(res => {
          this.articleTags = res.data.result.records
        })
      },
      //监听新增关闭
      addDialogClosed() {
        this.$refs.addFormRef.resetFields()
      },
      //监听编辑关闭
      editDialogClosed() {
        this.$refs.editFormRef.resetFields()
      },
      //展示编辑框
      showEditDialog(id) {
        this.$http.get(`/system-api/article/tag/${id}`).then(res => {
          if (res.data.code === 200) {
            this.editForm = res.data.result
          }
        })
        this.editDialogVisible = true
      },
      save() {
        this.$refs.addFormRef.validate(async valid => {
          if (!valid) return
          //可以发起添加用户的网路请求
          const {data: res} = await this.$http.post(`/system-api/article/tag`, this.articleTagVO)
          if (res.code !== 201) {
            return this.$message.error(res.message)
          }
          this.$message.success(res.message)
          //隐藏添加用户的对话框
          this.addDialogVisible = false
          //重新获取用户列表数据
          await this.list()
        })
      },
      edit() {
        this.$refs.editFormRef.validate(async valid => {
          if (!valid) return
          //可以发起添加用户的网路请求
          const {data: res} = await this.$http.put(`/system-api/article/tag`, this.editForm)
          if (res.code !== 200) {
            return this.$message.error(res.message)
          }
          this.$message.success(res.message)
          //隐藏编辑标签的对话框
          this.editDialogVisible = false
          await this.list()
        })
      },
      async removeTag(id) {
        const {data: res} = await this.$http.delete(`/system-api/article/tag/${id}`)
        if (res.code === 200) {
          this.list()
          return this.$message.success(res.message)
        }
        this.$message.error(res.message)
      }
    }
  }
</script>

<style scoped>

</style>
