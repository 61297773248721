<template>
  <div class="login_container">
    <!--登录表单区域--><!--:model 这里是属性绑定，在data中定义-->
    <el-form ref="loginFormRef"
             :model="loginForm"
             :rules="loginFormRoles"
             class="login_form"
             auto-complete="on"
             label-position="left">
      <h3 class="title">咖啡屋后台管理</h3>
      <!--用户名-->
      <el-form-item prop="userName">
        <!--这里通过v-model双向绑定的每个属性中-->
        <el-input autocomplete="off" placeholder="请输入注册的用户名" v-model="loginForm.userName"
                  prefix-icon="iconfont iconicon_login_input_user"></el-input>
      </el-form-item>
      <!--密码-->
      <el-form-item prop="password">
        <el-input placeholder="请输入注册的密码" v-model="loginForm.password" show-password
                  prefix-icon="iconfont iconicon_login_input_password"></el-input>
      </el-form-item>
      <!--按钮-->
      <el-form-item>
        <el-button class="tm" type="primary" @click="login" style="width: 100%">登&nbsp&nbsp&nbsp&nbsp&nbsp录</el-button>
        <!--<el-button type="primary" @click="resetLoginForm">重置</el-button>-->
      </el-form-item>
    </el-form>
    <!--引入粒子特效-->
    <vue-particles
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="80"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data() {
      return {
        //这是登录表单的数据绑定对象
        loginForm: {
          userName: '',
          password: ''
        },
        //这是表单的验证规则对象
        loginFormRoles: {
          //验证用户名
          userName: [
            {required: true, message: '请输入登录名', trigger: 'blur'},
            {min: 3, max: 15, message: '长度在 10 到 15 个字符', trigger: 'blur'}
          ],
          //验证密码
          password: [
            {required: true, message: '请输入密码', trigger: 'blur'},
            {min: 3, max: 15, message: '长度在 10 到 15 个字符', trigger: 'blur'}
          ]
        }

      }
    },
    methods: {
      //点击重置按钮，重置登录表单
      resetLoginForm() {
        // console.log(
        //   this //这个this指向当前登录表单的实例对象
        // )
        this.$refs.loginFormRef.resetFields()
      },
      login() {
        //this.$refs 表单的引用，loginFormRef是表单的引用对象
        this.$refs.loginFormRef.validate(async valid => {
          //console.log(valid)
          if (!valid) return;
          //当返回值是promise时，可以用await简化操作，紧挨着await的方法需要用async修饰
          const {data: res} = await this.$http.post("/auth-api/security/login", this.loginForm)
          if (res.code !== 200) return this.$message.error(res.message)
          this.$message.success(res.message)
          // 1．将登录成功之后的token，保存到客户端的localStorage中
          window.localStorage.setItem("token", res.result.token)
          window.localStorage.setItem("nickName", res.result.userInfo.nickName)
          window.localStorage.setItem("avatar", res.result.userInfo.avatar)
          //1.1项目中出了登录之外的其他API接口，必须在登录之后才能访问
          //1.2token只应在当前网站打开期间生效，所以将 token保存在localStorage
          // 2．通过编程式导航跳转到后台主页，路由地址是/home
          //3.将系统的配置信息，保存到localStorage中
          //window.localStorage.setItem("config", res.result.config)
          await this.$router.push('/home')
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  /*.el-input__inner /deep/ .tm {*/
  /*  background: rgba(0, 0, 0, 0.1) !important;*/
  /*}*/

  .el-input /deep/ .el-input__inner {
    background: rgba(0, 0, 0, 0);

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #141F2B inset !important;
      -webkit-text-fill-color: #fff !important;
    }
  }

  .tm {
    background: rgba(0, 0, 0, 0);
  }

  /*页面容器*/
  .login_container {
    position: relative;
    background-color: #141F2B;
    width: 100%;
    height: 100%;
  }

  /*登录表都*/
  .login_form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  /*标题*/
  .title {
    font-size: 26px;
    font-weight: 400;
    color: #eee;
    margin: 0px auto 40px auto;
    text-align: center;
    font-weight: bold;
  }

  /*登录盒子*/
  /*.login_box {*/
  /*  height: 300px;*/
  /*  width: 450px;*/
  /*  background-color: #ffffff;*/
  /*  border-radius: 3px; //圆角*/
  /*  position: absolute; //定位*/
  /*  left: 50%;*/
  /*  top: 50%;*/
  /*  transform: translate(-50%, -50%); //横轴纵轴位移*/
  /*}*/
</style>
