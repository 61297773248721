<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>权限列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片视图-->
    <el-card>
      <el-row>
        <el-button type="primary" @click="showAddMenu">添加菜单</el-button>
      </el-row>
      <!--selection-type 是否显示多选；
      expand-type 是否显示展开行;
      show-index是否显示索引;
      index-text索引列名称；
      border是否显示纵向边框;
      show-row-hover鼠标悬停是否高亮；
      -->
      <tree-table :data="rightsList"
                  :columns="columns"
                  :selection-type="false"
                  :expand-type="false"
                  show-index
                  index-text="#"
                  border
                  :show-row-hover="true"
                  class="tableBox"
                  :cell-style="{'text-align':'center'}"
                  :header-cell-style="{'text-align':'center'}"
      >
        <!--禁用资源-->
        <template slot="disable" slot-scope="scope">
          <el-switch
            v-model="scope.row.disable"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="disableRightById(scope.row.id,scope.row.disable)">
          </el-switch>
        </template>
        <!--是否可用-->
        <template slot="isOk" slot-scope="scope">
          <i class="el-icon-success" v-if="scope.row.disable===1" style="color: lightgreen;font-size:17px"></i>
          <i class="el-icon-error" style="color: red;font-size:17px" v-else></i>
        </template>
        <!--资源等级-->
        <template slot="perType" slot-scope="scope">
          <el-tag size="max" v-if="scope.row.perType=== 0">一级</el-tag>
          <el-tag size="max" type="success" v-else-if="scope.row.perType===1">二级</el-tag>
          <el-tag size="max" type="warning" v-else>三级</el-tag>
        </template>
        <!--排序-->
        <template slot="sortNo" slot-scope="scope">
          <el-tag type="success">{{scope.row.sortNo}}</el-tag>
        </template>
        <!--操作-->
        <template slot="opt" slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑
          </el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeRightById(scope.row.id)">删除
          </el-button>
        </template>
      </tree-table>
      <!--新增菜单对话框-->
      <el-dialog
        title="添加资源"
        :visible.sync="setMenuDialogVisible"
        width="50%"
        @close="addDialogClosed">
        <!--内容主题区域-->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
          <el-form-item label="菜单名称" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <!--资源类型-->
          <el-form-item label="资源类型" prop="level">
            <template>
              <el-select v-model="addForm.perType" @change="selectParentId" placeholder="请选择" clearable>
                <el-option
                  v-for="item in menuLevelDictList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-form-item>
          <!--父级菜单-->
          <el-form-item
            v-if="addForm.perType === 1 || addForm.perType === 2" label="父级菜单" prop="parentId">
            <!--
            options 用来指定数据源
            props 用来指定配置对象
            -->
            <el-cascader
              expand-trigger="hover"
              v-model="selectedKeys"
              :options="parentMenuList"
              :props="menuProps"
              @change="parentMenuChange"
              clearable
              :change-on-select="true"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="组件路由地址" prop="url">
            <el-input v-model="addForm.component"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sortNo">
            <el-input v-model="addForm.sortNo"></el-input>
          </el-form-item>
          <el-form-item label="资源图标" prop="icon">
            <el-input v-model="addForm.icon"></el-input>
          </el-form-item>
          <el-form-item label="是否有效" prop="disable">
            <el-radio v-model="addForm.disable" :label="1">有效</el-radio>
            <el-radio v-model="addForm.disable" :label="0">无效</el-radio>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setMenuDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addMenu">确 定</el-button>
        </span>
      </el-dialog>
      <!--编辑菜单对话框-->
      <el-dialog
        title="修改资源"
        :visible.sync="editDialogVisible"
        width="50%"
        @close="editDialogClosed">
        <!--内容主题区域-->
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
          <el-form-item label="菜单名称" prop="name">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <!--资源类型-->
          <el-form-item label="资源类型" prop="level">
            <template>
              <el-select v-model="editForm.perType" @change="selectParentId" placeholder="请选择" disabled>
                <el-option
                  v-for="item in menuLevelDictList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-form-item>
          <!--父级菜单-->
          <el-form-item
            v-if="addForm.level === 1 || addForm.level === 2" label="父级资源" prop="parentId">
            <!--
            options 用来指定数据源
            props 用来指定配置对象
            -->
            <el-cascader
              placeholder="请选择父级资源"
              expand-trigger="hover"
              v-model="selectedKeys"
              :options="parentMenuList"
              :props="menuProps"
              @change="parentMenuChange"
              clearable
              :change-on-select="true"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="组件路由" prop="component">
            <el-input v-model="editForm.component"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sortNo">
            <el-input v-model="editForm.sortNo"></el-input>
          </el-form-item>
          <el-form-item label="资源图标" prop="icon">
            <el-input v-model="editForm.icon"></el-input>
          </el-form-item>
          <el-form-item label="是否有效" prop="disable">
            <el-radio v-model="editForm.disable" :label="1">有效</el-radio>
            <el-radio v-model="editForm.disable" :label="0">无效</el-radio>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editRight">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: "Rights",
    data() {
      return {
        //是否禁用
        //权限列表数据
        rightsList: [],
        //为table指定列的定义
        columns: [
          {
            label: '资源名称',
            prop: 'name'
          },
          {
            label: '是否有效',
            //将当前列定义为模板列
            type: 'template',
            //当前模板列使用的名称
            template: 'isOk'
          },
          {
            label: '禁用资源',
            //将当前列定义为模板列
            type: 'template',
            //当前模板列使用的名称
            template: 'disable'
          },
          {
            label: '资源等级',
            //将当前列定义为模板列
            type: 'template',
            //当前模板列使用的名称
            template: 'perType'
          },
          {
            label: '资源排序',
            //将当前列定义为模板列
            type: 'template',
            //当前模板列使用的名称
            template: 'sortNo'
          },
          {
            label: '操作',
            //将当前列定义为模板列
            type: 'template',
            //当前模板列使用的名称
            template: 'opt'
          }
        ]
        ,
        //新增菜单对话的显示与隐藏
        setMenuDialogVisible: false,
        addForm: {
          name: '',
          component: '',
          perType: '',
          parentId: 0,
          icon: '',
          disable: 1,
          sortNo: 99
        },
        //添加资源表单校验规则
        addFormRules: {
          name: [
            {required: true, message: '请输入菜单名称', trigger: 'blur'},
          ],
          parentId: [
            {required: true, message: '请选中父级菜单', trigger: 'change'},
          ],
          perType: [
            {required: true, message: '请选择资源类型', trigger: 'change'}
          ],
          icon: [
            {required: true, message: '请输入图标', trigger: 'blur'}
          ]

        },
        //父级分类的列表
        parentMenuList: [],
        //指定级联选择器的配置对象
        menuProps: {
          //指定具体选中的值的属性
          value: 'id',
          //指定看到的属性
          label: 'name',
          //父子嵌套用的属性
          children: 'children'
        },
        //选中的父级分类的id数组
        selectedKeys: [],
        //下拉选择器
        menuLevelDictList: [
          {
            value: 0,
            label: '一级菜单'
          }, {
            value: 1,
            label: '二级菜单'
          },
          {
            value: 2,
            label: '按钮'
          }
        ],
        //控制修改资源对话框的显示与隐藏
        editDialogVisible: false,
        //查询到资源信息
        editForm: {},
        //编辑资源的校验规则
        editFormRules: {
          name: [
            {required: true, message: '请输入菜单名称', trigger: 'blur'},
          ],
          perType: [
            {required: true, message: '请选择资源类型', trigger: 'change'}
          ],
          parentId: [
            {required: true, message: '请选择父级资源', trigger: 'change'}
          ],
          icon: [
            {required: true, message: '请输入图标', trigger: 'blur'}
          ]
        }
      }
    },
    created() {
      //获取所有权限
      this.getRightsList()
    },
    methods: {
      //获取权限列表
      async getRightsList() {
        const {data: res} = await this.$http.get('/system-api/permission', {
          params: {
            type: 'tree'
          }
        })
        if (res.code !== 200) {
          return this.$message.error("获取权限列表失败")
        }
        this.rightsList = res.result
      },
      //监听添加菜单对话框的关闭事件
      addDialogClosed() {
        this.$refs.addFormRef.resetFields()
      },
      //监听添加菜单对话框打开
      showAddMenu() {
        //先获取父级菜单数据
        this.getParentPermission()
        this.addForm = {
          name: '',
          component: '',
          perType: '',
          parentId: 0,
          icon: '',
          disable: 1
        }
        //再展示对话框
        this.setMenuDialogVisible = true
      },
      //点击按钮添加菜单
      addMenu() {
        this.$refs.addFormRef.validate(valid => {
          if (!valid) return
          //可以发起请求
          this.$http.post('/system-api/permission', this.addForm).then(res => {
            //console.log(res)
            if (res.data.code !== 201) {
              this.$message.error(res.data.message)
            }
            this.$message.success(res.data.message);
            //隐藏添加对话框
            this.setMenuDialogVisible = false
            this.getRightsList()
          })

          //this.$message.success(res.message)

        })
      },
      //展示编辑菜单的对话框
      async showEditDialog(id) {
        const {data: res} = await this.$http.get('/system-api/permission/' + id)
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.editForm = res.result
        this.editDialogVisible = true;
      },
      //监听编辑菜单对话框的关闭
      editDialogClosed() {
        this.$refs.editFormRef.resetFields()
      },
      //修改资源并提交
      editRight() {
        this.$refs.editFormRef.validate(async valid => {
          if (!valid) return
          //发起修改资源的请求
          const {data: res} = await this.$http.put("/system-api/permission", this.editForm)
          if (res.code !== 200) {
            return this.$message.error(res.message)
          }
          this.$message.success(res.message)
          await this.getRightsList()
          this.editDialogVisible = false
        })
      },
      //禁用资源
      async disableRightById(id, disable) {
        const edit = {
          id: id,
          disable: disable
        }
        const {data: result} = await this.$http.put('/system-api/permission', edit)
        if (result.code !== 200) {
          return this.$message.error(result.message)
        }
        this.$message.success(result.message)
      },

      //删除资源
      async removeRightById(id) {
        //消息提示是否删除
        const res = await this.$confirm('此操作将永久删除该资源及其子资源, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        if (res !== 'confirm') {
          return this.$message.info("已经取消了删除")
        }
        const {data: result} = await this.$http.delete('/system-api/permission/' + id)
        if (result.code !== 200) {
          return this.$message.error(result.message)
        }
        this.$message.success(result.message)
        await this.getRightsList()
      },

      //获取父级资源
      async getParentPermission() {
        const {data: res} = await this.$http.get('/system-api/permission?perType=0&type=list')
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.parentMenuList = res.result
      },
      //级联选择器选择项发生变化触发这个函数
      parentMenuChange() {
        //如果 selectKeys 数组中的 length 长度大于0，证明选中了父级分类
        //反之，就说明没有选中父级
        if (this.selectedKeys.length > 0) {
          //父级分类的id
          this.addForm.parentId = this.selectedKeys[this.selectedKeys.length - 1]
          //为当前分类的等级赋值,这里取的时资源类型那一列
          this.addForm.level = this.selectedKeys.length
        } else {
          //父级分类的id
          this.addForm.parentId = ''
          //为当前分类的等级赋值
          this.addForm.perType = 1
        }
      },
      //资源等级选择器选中时触发
      selectParentId() {
        this.selectedKeys = []
        this.addForm.parentId = 0
      }
    }
  }
</script>

<style lang="less" scoped>
  .tableBox /deep/ .zk-table__cell-inner {
    height: 53px;
    line-height: 53px;
    padding: 0 !important;
    font-size: 13px;
  }

  .tableBox {
    margin-top: 15px;
  }

  .el-cascader {
    width: 100%;
  }
</style>
