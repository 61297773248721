// import {showdown} from 'showdown'
// import {TurndownService} from 'turndown'

/** **********************************************************/
/**
 *  全局状态码
 */
const ECode = {
  // 默认页大小
  SUCCESS: "success",
  // 默认页码
  ERROR: "error",
}

/** **********************************************************/

/**
 * 通用工具类
 */
const FUNCTIONS = {
  /**
   * 复制文字到剪切板
   * @param text
   */
  copyText: text => {
    const oInput = document.createElement('input')
    oInput.value = text
    document.body.appendChild(oInput)
    oInput.select() // 选择对象
    document.execCommand('Copy') // 执行浏览器复制命令
    oInput.className = 'oInput'
    oInput.style.display = 'none'
  }
}

export default {
  ECode,
  FUNCTIONS
}
