<template>
  <div>
    <el-form :model="systemConfigForm" :rules="systemConfigRules" ref="systemConfigFormRef" label-width="150px"
             style="width: 100%"
             class="demo-ruleForm"
             label-position="left"
    >
      <el-tabs type="border-card">
        <!--图库配置-->
        <el-tab-pane label="图库配置">
          <el-form-item label="图片存储位置" prop="storageType">
            <el-radio v-model="systemConfigForm.storageType" :label="1" border>本地存储</el-radio>
            <el-radio v-model="systemConfigForm.storageType" :label="2" border>OSS存储</el-radio>
            <el-radio v-model="systemConfigForm.storageType" :label="3" border>Minio存储</el-radio>
            <el-radio v-model="systemConfigForm.storageType" :label="4" border>七牛云存储</el-radio>
          </el-form-item>
        </el-tab-pane>
        <!--oss配置-->
        <el-tab-pane label="OSS配置">
          <el-form-item label="公钥" prop="ossAccessKeyId">
            <el-input v-model="systemConfigForm.ossAccessKeyId"></el-input>
          </el-form-item>
          <el-form-item label="私钥" prop="ossAccessKeySecret">
            <el-input v-model="systemConfigForm.ossAccessKeySecret"></el-input>
          </el-form-item>
          <el-form-item label="bucketName" prop="ossBucketName">
            <el-input v-model="systemConfigForm.ossBucketName"></el-input>
          </el-form-item>
          <el-form-item label="endPoint" prop="ossEndPoint">
            <el-input v-model="systemConfigForm.ossEndPoint"></el-input>
          </el-form-item>
          <el-form-item label="domain" prop="ossDomain">
            <el-input v-model="systemConfigForm.ossDomain"></el-input>
          </el-form-item>
        </el-tab-pane>
        <!--minio配置-->
        <el-tab-pane label="Minio配置">
          <el-form-item label="公钥" prop="minioAccessKey">
            <el-input v-model="systemConfigForm.minioAccessKey"></el-input>
          </el-form-item>
          <el-form-item label="私钥" prop="minioSecretKey">
            <el-input v-model="systemConfigForm.minioSecretKey"></el-input>
          </el-form-item>
          <el-form-item label="bucketName" prop="minioBucketName">
            <el-input v-model="systemConfigForm.minioBucketName"></el-input>
          </el-form-item>
          <el-form-item label="endPoint" prop="minioEndPoint">
            <el-input v-model="systemConfigForm.minioEndPoint"></el-input>
          </el-form-item>
          <el-form-item label="domain" prop="minioDomain">
            <el-input v-model="systemConfigForm.minioDomain"></el-input>
          </el-form-item>
        </el-tab-pane>
        <!--七牛云配置-->
        <el-tab-pane label="七牛云配置">
          <el-form-item label="公钥" prop="qiNiuAccessKey">
            <el-input v-model="systemConfigForm.qiNiuAccessKey"></el-input>
          </el-form-item>
          <el-form-item label="私钥" prop="qiNiuSecretKey">
            <el-input v-model="systemConfigForm.qiNiuSecretKey"></el-input>
          </el-form-item>
          <el-form-item label="bucketName" prop="qiNiuBucketName">
            <el-input v-model="systemConfigForm.qiNiuBucketName"></el-input>
          </el-form-item>
          <el-form-item label="endPoint" prop="qiNiuArea">
            <el-input v-model="systemConfigForm.qiNiuArea"></el-input>
          </el-form-item>
          <el-form-item label="domain" prop="qiNiuDomain">
            <el-input v-model="systemConfigForm.qiNiuDomain"></el-input>
          </el-form-item>
        </el-tab-pane>
        <!--邮箱-->
        <el-tab-pane label="邮箱配置">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="systemConfigForm.email"></el-input>
          </el-form-item>
          <el-form-item label="邮箱用户名" prop="emailUserName">
            <el-input v-model="systemConfigForm.emailUserName"></el-input>
          </el-form-item>
          <el-form-item label="邮箱密码" prop="emailPassword">
            <el-input v-model="systemConfigForm.emailPassword" type="password"></el-input>
          </el-form-item>
          <el-form-item label="smtp服务地址" prop="smtpAddress">
            <el-input v-model="systemConfigForm.smtpAddress"></el-input>
          </el-form-item>
          <el-form-item label="smtp服务端口" prop="smtpPort">
            <el-input v-model="systemConfigForm.smtpPort"></el-input>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
      <el-form-item>
        <el-button type="primary" @click="submitSystemConfigForm">提交</el-button>
        <!--        <el-button @click="resetSystemConfigFormForm">重置</el-button>-->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: "SystemConfig",

    created() {
      this.getSystemConfig()
    },
    data() {
      return {
        //系统配置表单
        systemConfigForm: {
          //邮箱
          email: '',
          //邮箱用户名
          emailUserName: '',
          //邮箱密码
          emailPassword: '',
          //smtp服务地址
          smtpAddress: '',
          //smtp服务端口
          smtpPort: '',
          //主题颜色
          themeColor: '',
          //七牛云公钥
          qiNiuAccessKey: '',
          //七牛云私钥
          qiNiuSecretKey: '',
          //七牛云上传空间
          qiNiuBucketName: '',
          //七牛云存储区域
          qiNiuArea: '',
          //七牛云域名前缀
          qiNiuDomain: '',
          //Minio公钥
          minioAccessKey: '',
          //Minio私钥
          minioSecretKey: '',
          //Minio桶
          minioBucketName: '',
          //Minio远程连接地址
          minioEndPoint: '',
          //Minio服务器文件域名前缀
          minioDomain: '',
          //oss key
          ossAccessKeyId: '',
          //oss secret
          ossAccessKeySecret: '',
          //oss bucket_name
          ossBucketName: '',
          //oss end_point
          ossEndPoint: '',
          //oss 域名
          ossDomain: '',
          //存储类型 1本地 2oss 3 minio
          storageType: '',
          //本地服务器域名前缀
          localDomain: '',
        },
        //校验规则
        systemConfigRules: {
          storageType: [
            {required: true, message: '请选图片存储位置', trigger: 'change'}
          ]
        }
      }
    },
    methods: {
      //提交表单
      submitSystemConfigForm() {
        this.$refs.systemConfigFormRef.validate(valid => {
          if (!valid) return
          //发起请求
          this.$http.put('/system-api/config/system', this.systemConfigForm).then(res => {
            if (res.data.code !== 200) {
              return this.$message.error(res.data.message)
            }
            this.$message.success(res.data.message)
          })
        })
      },
      //获取系统配置
      getSystemConfig() {
        this.$http.get('/system-api/config/system').then(res => {
          this.systemConfigForm = res.data.result[0]
        })
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
