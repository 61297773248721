import Vue from 'vue'
import App from './App'
import router from './router'
import './assets/css/global.css'
//屏蔽掉，从cdn引入
//import './plugins/element.js'
// import Element from 'element-ui'
//import 'element-ui/lib/theme-chalk/index.css'
import store from './store'
//引入字体图标
import './assets/fonts/iconfont.css'

import TreeTable from 'vue-table-with-tree-grid'
//引入axios
import Axios from "axios"
//引入md
import MavonEditor from 'mavon-editor'
// 引入全局工具类
import prototype from './utils/prototype'
//优化
import VueParticles from 'vue-particles'

Vue.use(VueParticles)

Vue.use(MavonEditor)

Vue.use(prototype)

//配置请求的根路径
//Axios.defaults.baseURL = 'http://localhost:8999'
Axios.interceptors.request.use(config => {
  //console.log(config)
  config.headers['X-Access-Token'] = window.localStorage.getItem("token")
  //在最后必须return config
  return config
})
//后置拦截
Axios.interceptors.response.use(response => {
    const res = response.data
    if (res.code === 200) {
      return response
    }
    if (res.code === 201) {
      return response
    }
    if (res.code === 401) {
      Element.Message.error(res.message);
      window.localStorage.clear()
      router.push('/login')
      return response
    } else {
      Element.Message.error(res.message);
      return Promise.reject(res.message);
    }
  },
  error => {
    if (error.response.data) {
      error.message = error.response.data.message
    }
    //token失效，清空浏览器缓存token,重定向到登录页
    if (error.response.status === 401) {
      Element.Message.error(error.message);
      this.$router.push('/login')
    } else {
      Element.Message.error(res.message);
      return Promise.reject(error);
    }
  }
);
Vue.prototype.$http = Axios


Vue.config.productionTip = false

Vue.component('tree-table', TreeTable)
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
