<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>图片管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/picture/sort'}">分类管理</el-breadcrumb-item>
      <el-breadcrumb-item>图片列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--按钮区域-->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="2">
          <el-input v-model="queryInfo.name" placeholder="请输入名称"></el-input>
        </el-col>
        <el-col :span="2">
          <el-select v-model="queryInfo.storageType" placeholder="请选择来源">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-button type="primary" @click="getPictureList" icon="el-icon-search">查询</el-button>
        <el-button type="primary" @click="resetQueryInfo">重置</el-button>
        <el-button type="primary" @click="uploadPictureDialog=true">添加图片</el-button>
        <el-button type="warning" @click="checkAll" style="width: 100px">{{this.chooseTitle}}</el-button>
        <el-button type="danger" @click="deleteSelect">删除选中</el-button>
        <el-button type="success" @click="toCover">设为封面</el-button>
      </el-row>
    </el-card>
    <!--图片列表card-->
    <el-card>
      <el-row>
        <el-col :span="4" v-for="(picture, index) in pictureList" :key="index">
          <el-card class="picture" :body-style="{ padding: '10px' ,height:'150px',width: '210px'}">
            <label>
              <input style="position: absolute;z-index: 100;" type="checkbox" :id="picture.id"
                     @click="checked(picture)" :checked="picIds.indexOf(picture.id)>=0">
            </label>
            <el-image :src="picture.picUrl" class="el-image__inner el-image__preview"
                      :preview-src-list="picture.picUrlList"></el-image>
            <div style="padding: 1px;">
              <div class="title">
                <span class="media-title">{{picture.name}}</span>
              </div>
              <div class="buttonDiv">
                <el-tooltip effect="dark" content="复制url" placement="bottom" :enterable="false">
                  <el-button type="success" icon="el-icon-document-copy" size="mini"
                             @click="copeUrl(picture.picUrl)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="复制MarkDown格式url" placement="bottom" :enterable="false">
                  <el-button type="warning" icon="el-icon-s-open" size="mini"
                             @click="copeMarkDownUrl(picture.name,picture.picUrl)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="删除图片" placement="bottom" :enterable="false">
                  <el-button type="danger" icon="el-icon-delete" size="mini"
                             @click="deleteByPid(picture.id)"></el-button>
                </el-tooltip>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.total">
      </el-pagination>
    </el-card>
    <!--上传图片对话框-->
    <el-dialog
      title="提示"
      :visible.sync="uploadPictureDialog"
      width="50%">
      <el-upload
        class="upload-demo"
        drag
        :action="uploadUrl"
        multiple
        :headers="headersData"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :data="pictureVO"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
    <el-button @click="uploadPictureDialog = false">取 消</el-button>
    <el-button type="primary" @click="uploadPictureDialog = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "PictureList",
    data() {
      return {
        //查询参数
        queryInfo: {
          pageNo: 1,
          pageSize: 10,
          name: '',
          //分类id
          categoryId: '',
          storageType: ''
        },
        pictureVO: {
          categoryId: '',
          categoryCode: '',
          storageType: ''
        },
        //图片列表
        pictureList: [],
        //总条数
        total: 0,
        //控制上传图片对话框的显示与隐藏
        uploadPictureDialog: false,
        //上传地址
        uploadUrl: 'https://www.coffeeo.cn/file-api/picture',
        //上传图片额外的参数
        headersData: {
          "X-Access-Token": window.localStorage.getItem("token")
        },
        //选中的图片的id
        picIds: [],
        //是否全选
        isCheckedAll: false,
        //全选，反选
        chooseTitle: '全选',
        options: [
          {
            value: 1,
            label: '本地存储'
          }, {
            value: 2,
            label: 'oss存储'
          }, {
            value: 3,
            label: 'minio存储'
          }],
      }
    },
    created() {
      this.queryInfo.categoryId = this.$route.params.id
      //获取配置
      this.getPictureConfig(this.queryInfo.categoryId);
      //获取图片列表数据
      this.getPictureList()
    },
    methods: {
      //获取图片列表
      getPictureList() {
        //console.log(this.queryInfo)
        this.$http.get('/system-api/picture', {params: this.queryInfo}).then(res => {
          //console.log(res.data.result.records)
          this.pictureList = res.data.result.records
          this.total = res.data.result.total
        })
      },
      //监听每页条数的变化
      handleSizeChange(newPageSize) {
        this.queryInfo.pageSize = newPageSize
        this.getPictureList()
      },
      //监听页码数的变化
      handleCurrentChange(newPageNo) {
        this.queryInfo.pageNo = newPageNo
        this.getPictureList()
      },
      //文件上传成功后的函数
      uploadSuccess: function (response) {
        if (response.code === 500) {
          this.$message.error('上传失败');
        } else {
          this.$message.success('上传成功');
          this.getPictureList()
        }
      },
      uploadError: function () {
        this.$message.error('上传失败')
      },
      //监控点击单选
      checked: function (data) {
        let idIndex = this.picIds.indexOf(data.id);
        if (idIndex >= 0) {
          //选过了
          this.picIds.splice(idIndex, 1);
        } else {
          this.picIds.push(data.id);
        }
        //判断下当前所有选中的图片是否已经全选
        if (this.picIds.length === this.pictureList.length) {
          this.isCheckedAll = true
          this.chooseTitle = '取消全选'
        } else {
          this.isCheckedAll = false
          this.chooseTitle = '全选'
        }
      },
      //监控点击全选按钮
      checkAll() {
        //判断当前状态，已经全选
        if (this.isCheckedAll) {
          this.picIds = []
          this.isCheckedAll = false;
          this.chooseTitle = '全选'
        } else {
          this.picIds = []
          this.pictureList.forEach(e => {
            this.picIds.push(e.id)
          })
          this.isCheckedAll = true
          this.chooseTitle = '取消全选'
        }
      },
      //删除选中图片
      deleteSelect() {
        if (this.picIds.length <= 0) {
          return this.$message.error('请先选择图片')
        }
        //需要删除图片id
        const ids = this.picIds.join(',')
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          if (res !== 'confirm') {
            return this.$message.info("已经取消了删除")
          }
          this.$http.delete('/file-api/picture/' + ids).then(res => {
            if (res.data.code !== 200) {
              this.$message.error(res.data.message)
            }
            this.$message.success(res.data.message)
            this.getPictureList()
            this.picIds = []
            this.chooseTitle = '全选'
            this.isCheckedAll = false
          })
        })
      },
      //更改封面
      toCover() {
        if (this.picIds.length <= 0) {
          return this.$message.error('请先选择图片')
        }
        const putData = {
          picId: this.picIds[0],
          categoryId: this.queryInfo.categoryId
        }
        this.$http.put('/system-api/picture/category', putData).then(res => {
          if (res.data.code !== 200) {
            return this.$message.error('设置失败')
          }
          this.$message.success('设置成功')
          this.picIds = []
          this.chooseTitle = '全选'
          this.isCheckedAll = false
        })
      },
      //点击图片下方的删除按钮
      deleteByPid(id) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          if (res !== 'confirm') {
            return this.$message.info("已经取消了删除")
          }
          this.$http.delete('/file-api/picture/' + id).then(res => {
            if (res.data.code !== 200) {
              this.$message.error(res.data.message)
            }
            this.$message.success(res.data.message)
            this.getPictureList()
            this.picIds = []
            this.chooseTitle = '全选'
            this.isCheckedAll = false
          })
        })
      },
      //cope 图片url
      copeUrl(picUrl) {
        this.$commonUtil.copyText(picUrl)
        this.$message.success('复制链接到剪切板成功')
      },
      //cope 图片url为MarkDownUrl
      copeMarkDownUrl(name, picUrl) {
        const text = '![' + name + '](' + picUrl + ')'
        this.$commonUtil.copyText(text)
        this.$message.success('复制Markdown格式链接到剪切板成功')
      },
      //重置搜索框
      resetQueryInfo() {
        this.queryInfo = {
          pageNo: 1,
          pageSize: 10,
          name: '',
          picSortId: '',
          storageType: ''
        }
        //重新获取数据
        this.getPictureList()
      },
      //获取系统配置
      getPictureConfig(id) {
        this.$http.get('/system-api/picture/config', {
          params: {
            categoryId: id
          }
        }).then(res => {
          this.pictureVO = res.data.result
          console.log(this.pictureVO)

        })
      }
    },
  }
</script>

<style lang="less" scoped>

  .el-card {
    margin-top: 10px;
  }

  .el-row {
    margin-top: 10px;
  }

  .picture {
    margin-top: 10px;
    margin-left: 10px;
    width: 230px;
    height: 250px;
    horiz-align: center;
  }

  .media-title {
    color: #8492a6;
    font-size: 14px;
    padding: 14px;
    display: inline-block;
    white-space: nowrap;
    width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .buttonDiv {
    text-align: center;
  }
</style>
