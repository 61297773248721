<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>配置管理</el-breadcrumb-item>
      <el-breadcrumb-item>友链配置</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片视图区域-->
    <el-card>
      <!--搜索框与添加区域-->
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入内容" v-model="queryInfo.userName" :clearable="true" @clear="list">
            <el-button slot="append" icon="el-icon-search" @click="list"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="showAddDialog">添加友链</el-button>
        </el-col>
      </el-row>
      <!--列表区域-->
      <el-table :data="linkList"
                border stripe
                :cell-style="{'text-align':'center'}"
                :header-cell-style="{'text-align':'center'}">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="友链logo" prop="linkLogo" width="280px"></el-table-column>
        <el-table-column label="友链名称" prop="linkName" width="120px"></el-table-column>
        <el-table-column label="友链地址" prop="linkUrl" width="280px"></el-table-column>
        <el-table-column label="友链状态">
          <template slot-scope="scope">
            <el-tag size="max" v-if="scope.row.linkStatus=== 0">申请中</el-tag>
            <el-tag size="max" type="success" v-else-if="scope.row.linkStatus===1">已上线</el-tag>
            <el-tag size="max" type="warning" v-else>已下架</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="更新时间" prop="updateTime"></el-table-column>
        <el-table-column label="操作" min-width="200px">
          <template slot-scope="scope">
            <!--修改-->
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">修改
            </el-button>
            <!--删除-->
            <el-button type="danger" icon="el-icon-delete" size="mini"
                       @click="deleteLinkById(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNo"
          :page-sizes="[1, 2, 10, 15]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.total"
          style="text-align: right">
      </el-pagination>


      <!--对话框-->
      <el-dialog
          :title="this.opType === 'add' ? '新增友链':'修改友链'"
          :visible.sync="showForm"
          width="50%"
          @close="formClosed">
        <!--内容主题区域-->
        <el-form :model="form" :rules="formRules" ref="formRef" label-width="100px">
          <el-form-item label="友链名称" prop="linkName">
            <el-input v-model="form.linkName"></el-input>
          </el-form-item>
          <el-form-item label="友链logo" prop="linkLogo">
            <el-input v-model="form.linkLogo"></el-input>
          </el-form-item>
          <el-form-item label="友链地址" prop="linkUrl">
            <el-input v-model="form.linkUrl"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sortNum">
            <el-input v-model="form.sortNum"></el-input>
          </el-form-item>
          <el-form-item label="友链状态" prop="linkStatus">
            <el-select v-model="form.linkStatus" placeholder="请选择">
              <el-option
                  v-for="item in linkStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!--底部区域-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="showForm = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定
          </el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "LinkConfig",
  data() {
    return {
      showForm: false,
      queryInfo: {
        linkName: '',
        pageNo: 1,
        pageSize: 10,
      },
      form: {
        id: null,
        linkLogo: null,
        linkName: null,
        linkUrl: null,
        linkStatus: null,
        sortNum: null
      },
      formRules: {
        linkLogo: [
          {required: true, message: '请输入网站logo', trigger: 'blur'},
        ],
        linkName: [
          {required: true, message: '请输入网站名称', trigger: 'blur'},
        ],
        linkUrl: [
          {required: true, message: '请输入网站链接', trigger: 'blur'},
        ],
        linkStatus: [
          {required: true, message: '请选择网站状态', trigger: 'blur'},
        ],
      },
      total: 0,
      linkList: [],
      opType: '',
      //友链状态 TODO 字典
      linkStatusOptions: [{
        value: 0,
        label: '申请中'
      }, {
        value: 1,
        label: '已上线'
      }, {
        value: 2,
        label: '已下架'
      }
      ],
    }
  },
  created() {
    this.list()
  },
  methods: {
    list() {
      this.$http.get('system-api/config/link', {params: this.queryInfo}).then(res => {
        this.linkList = res.data.result.records
        this.total = res.data.result.total
      })
    },
    showAddDialog() {
      this.opType = 'add'
      this.showForm = true
    },
    showEditDialog(id) {
      this.opType = 'edit'
      this.showForm = true
      this.$http.get(`system-api/config/link/` + id).then(res => {
        this.form = res.data.result
        console.log(this.form)
      })
    },
    async deleteLinkById(id) {
      const confirmResult =
          await this.$confirm('此操作将永久删除该链接, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => err)
      //如果用户确认删除，则返回值为字符串confirm
      //如果用户取消了删除，则返回值为字符串cancel
      //console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        return this.$message.info("已经取消了删除")
      }
      const {data: res} = this.$http.delete("/system-api/config/link/" + id)
      if (res.code !== 200) {
        return this.$message.error("删除失败")
      }
      //刷新列表
      this.list()
      this.$message.success("删除成功")
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.list()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNo = newPage
      this.list()
    },
    submit() {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        if (this.opType === 'edit') {
          this.$http.put('system-api/config/link', this.form).then(res => {
            if (res.data.code !== 200) {
              this.$message.error(res.data.message)
            }
            this.list()
            this.$message.success(res.data.message)
          });
        }
        if (this.opType === 'add') {
          this.$http.post('system-api/config/link', this.form).then(res => {
            if (res.data.code !== 200) {
              this.$message.error(res.data.message)
            }
            this.list()
            this.$message.success(res.data.message)
          })
        }
        this.showForm = false;
      })
    },
    formClosed() {
      this.$refs.formRef.resetFields()
    }
  }
}
</script>

<style scoped>
.el-table {
  margin-top: 15px;
  font-size: 12px;
}
</style>