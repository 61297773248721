<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>日志管理</el-breadcrumb-item>
      <el-breadcrumb-item>日志列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片视图区域-->
    <el-card>
      <!--搜索框-->
      <el-row>
        <el-col :span="5">
          <el-input placeholder="请输入ip来源" v-model="queryInfo.ipSource" :clearable="true" @clear="getWebLogList"
                    style="width: 160px"></el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" icon="el-icon-search" @click="getWebLogList" style="width: 120px">查询日志
          </el-button>
          <el-button type="info" icon="el-icon-delete" @click="resetQueryInfo" style="width: 120px">重置</el-button>
        </el-col>
      </el-row>
      <!--文章列表区域-->
      <el-table :data="webLogList" stripe border style="width: 100%;margin-top: 15px" :row-style="rowClass"
                :cell-style="{'text-align':'center'}"
                :header-cell-style="{'text-align':'center'}">
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column label="日志内容" prop="content" width="150"></el-table-column>
        <el-table-column label="请求方法" prop="requestMethod" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column label="请求参数" prop="requestParam" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column label="请求类型" prop="requestType" width="80">
          <template slot-scope="scope">
            <el-tag
              :type="getType(scope.row.requestType)"
              disable-transitions>{{scope.row.requestType}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="请求url" prop="requestUrl" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column label="日志类型" prop="operationType" width="50"></el-table-column>
        <el-table-column label="访问模块" prop="model" width="150"></el-table-column>
        <el-table-column label="ip" prop="ip" width="150"></el-table-column>
        <el-table-column label="ip来源" prop="ipSource" width="150"></el-table-column>
        <el-table-column label="操作系统" prop="os" width="100"></el-table-column>
        <el-table-column label="浏览器" prop="browser" width="200"></el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="230">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
  export default {
    name: "WebLog",
    data() {
      return {
        //查询参数
        queryInfo: {
          pageNo: 1,
          pageSize: 10,
          ipSource: ''
        },
        //总条数
        total: '',
        //日志列表数据
        webLogList: []
      }
    },
    computed: {
      rowClass: function (row, index) {
        return {"height": "6  vh"}
      }
    },
    created() {
      this.getWebLogList()
    },
    methods: {
      //获取日志列表
      getWebLogList() {
        this.$http.get("/system-api/log/list", {params: this.queryInfo}).then(res => {
          if (res.data.code !== 200) {
            this.$message.error(res.data.message)
            return false
          }
          this.webLogList = res.data.result.records
          this.total = res.data.result.total
        })
      },
      //重置搜索项
      resetQueryInfo() {
        this.queryInfo = {
          pageNo: 1,
          pageSize: 10,
          ipSource: ''
        }
        this.getWebLogList()
      },
      //监听pageSize改变
      handleSizeChange(newSize) {
        this.queryInfo.pageSize = newSize
        this.getWebLogList()
      },
      //监听pageNo改变
      handleCurrentChange(newPage) {
        this.queryInfo.pageNo = newPage
        this.getWebLogList()
      },
      //获取类型
      getType(type) {
        return type === 'PUT' ? 'success' : type === 'POST' ? 'primary' : 'warning'
      }
    }
  }
</script>

<style scoped>

</style>
