<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>文章管理</el-breadcrumb-item>
      <el-breadcrumb-item>分类列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片视图区域-->
    <el-card>
      <!--搜索框与添加区域-->
      <el-row>
        <el-col :span="3">
          <el-input placeholder="请输入分类名" v-model="queryInfo.categoryName" :clearable="true" style="width: 160px">
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" icon="el-icon-search" @click="getArticleCategoryList" style="width: 120px">查询分类
          </el-button>
          <el-button type="info" icon="el-icon-delete" @click="resetQueryInfo" style="width: 120px">重置</el-button>
          <el-button type="success" icon="el-icon-edit" @click="dialogVisible=true" style="width: 120px">
            添加分类
          </el-button>
        </el-col>
      </el-row>
      <!--列表区域-->
      <el-table :data="categoryList"
                border stripe
                :cell-style="{'text-align':'center'}"
                :header-cell-style="{'text-align':'center'}">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="分类名称" prop="categoryName"></el-table-column>
        <el-table-column label="分类值" prop="categoryValue"></el-table-column>
        <!--        <el-table-column label="分类简介" prop="sortSummary"></el-table-column>-->
        <!--        <el-table-column label="排序字段" prop="sortValue">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-tag style="width: 60px;text-align: center"-->
        <!--                    type="success">-->
        <!--              {{scope.row.sortValue}}-->
        <!--            </el-tag>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--        <el-table-column label="创建时间" prop="createTime"></el-table-column>-->
        <!--        <el-table-column label="更新时间" prop="updateTime"></el-table-column>-->
        <el-table-column label="操作" min-width="150px">
          <template slot-scope="scope">
            <!--修改-->
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">修改
            </el-button>
            <!--删除-->
            <el-button type="danger" icon="el-icon-delete" size="mini"
                       @click="deleteArticleSortById(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination style="text-align: right"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.pageNo"
                     :page-sizes="[10, 15, 20, 25]"
                     :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <!--添加分类对话框-->
    <el-dialog
      :title="this.title"
      :visible.sync="dialogVisible"
      width="30%"
      @close="resetForm('categoryFormRef')">
      <el-form :model="categoryForm" :rules="categoryFormRules" ref="categoryFormRef" label-width="100px">
        <el-form-item label="分类名称" prop="categoryName">
          <el-input v-model="categoryForm.categoryName"></el-input>
        </el-form-item>
        <!--        <el-form-item label="分类简介" prop="sortSummary">-->
        <!--          <el-input v-model="categoryForm.sortSummary"></el-input>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="排序位置" prop="sortValue">-->
        <!--          <el-input v-model="categoryForm.sortValue"></el-input>-->
        <!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
<!--        <el-button type="danger" @click="resetForm('categoryFormRef')">重置</el-button>-->
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addArticleSort">确 定</el-button>
    </span>
    </el-dialog>
    <!--添加分类对话框-->
    <el-dialog
      :title="this.title"
      :visible.sync="dialogVisible"
      width="30%"
      @close="resetForm('categoryFormRef')">
      <el-form :model="categoryForm" :rules="categoryFormRules" ref="categoryFormRef" label-width="100px">
        <el-form-item label="分类名称" prop="categoryName">
          <el-input v-model="categoryForm.categoryName"></el-input>
        </el-form-item>
        <el-form-item label="分类值" prop="categoryValue">
          <el-input v-model="categoryForm.categoryValue"></el-input>
        </el-form-item>
        <!--        <el-form-item label="分类简介" prop="sortSummary">-->
        <!--          <el-input v-model="categoryForm.sortSummary"></el-input>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="排序位置" prop="sortValue">-->
        <!--          <el-input v-model="categoryForm.sortValue"></el-input>-->
        <!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
<!--        <el-button type="danger" @click="resetForm('categoryFormRef')">重置</el-button>-->
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addArticleSort">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "Category",
    data() {
      const checkSort = (rule, value, cb) => {
        //验证排序字段的正则表达式
        const retSort = /^[0-9]*$/
        if (retSort.test(value)) {
          return cb()
        }
        cb(new Error('请输入合法数字'))
      }
      return {
        total: 0,
        queryInfo: {
          categoryName: '',
          pageNo: 1,
          pageSize: 10
        },
        //文章分类
        categoryList: [],
        //添加分类对话框的显示与隐藏
        dialogVisible: false,
        //文章分类表单
        categoryForm: {
          id: '',
          categoryName: '',
          //sortSummary: '',
          categoryValue: ''
        },
        //文章分类表单校验
        categoryFormRules: {
          sortName: [
            {required: true, message: '请输入分类名称', trigger: 'blur'}
          ],
          sort: [
            {validator: checkSort, trigger: 'blur'}
          ]
        },
        title: '添加分类'
      }
    },
    created() {
      //获取分类列表
      this.getArticleCategoryList()
    },
    methods: {
      //获取分类列表
      getArticleCategoryList() {
        this.$http.get('/system-api/article/category', {params: this.queryInfo}).then(res => {
          if (res.data.code !== 200) {
            this.$message.error(res.data.message)
            return false
          }
          this.categoryList = res.data.result.records
          this.total = res.data.result.total
        })
      },
      //修改分类
      showEditDialog(id) {
        this.$http.get('/system-api/article/category/' + id).then(res => {
          this.categoryForm = res.data.result
          this.dialogVisible = true
        })
      },
      //删除分类
      deleteArticleSortById(id) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.delete('/system-api/article/category/' + id).then(res => {
            if (res.data.code !== 200) {
              this.$message.error(res.data.message)
              return false
            }
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getArticleSortList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //添加文章分类
      addArticleSort() {
        this.$refs.categoryFormRef.validate(valid => {
          if (!valid) {
            return false
          }
          //发起修改
          if (this.categoryForm.id) {
            this.$http.put('/system-api/article/category', this.categoryForm).then(res => {
              if (res.data.code !== 200) {
                this.$message.error(res.data.message)
              }
              this.getArticleCategoryList()
              this.$message.success(res.data.message)
              this.dialogVisible = false
            })
          } else {
            //发起新增
            this.$http.post('/system-api/article/category', this.categoryForm).then(res => {
              if (res.data.code !== 201) {
                this.$message.error(res.data.message)
                return false
              }
              this.getArticleCategoryList()
              this.$message.success(res.data.message)
              this.dialogVisible = false
            });
          }
        })
      },
      //重置搜索项
      resetQueryInfo() {
        this.queryInfo = {
          sortName: '',
          pageNo: 1,
          pageSize: 10
        }
        this.getArticleCategoryList()
      },
      //重置表单
      resetForm(formName) {
        //清空表单
        //console.log('清空表单')
        this.$refs[formName].resetFields()
      },
      handleSizeChange(value) {
        this.queryInfo.pageSize = value
        this.getArticleCategoryList()
      },
      handleCurrentChange(value) {
        this.queryInfo.pageNo = value
        this.getArticleCategoryList()
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-table {
    margin-top: 15px;
    font-size: 12px;
  }
</style>
