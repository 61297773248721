<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>文章管理</el-breadcrumb-item>
      <el-breadcrumb-item>文章列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片视图区域-->
    <el-card>
      <!--
      搜索框与添加区域
      :gutter="20" 与右边按钮的间隔
      -->
      <el-row>
        <el-col :span="5">
          <el-input placeholder="请输入标题" v-model="queryInfo.title" :clearable="true" style="width: 160px">
          </el-input>
          <el-select v-model="queryInfo.level" placeholder="请选择推荐等级" style="width: 160px">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" icon="el-icon-search" @click="getArticleList" style="width: 120px">查询文章
          </el-button>
          <el-button type="info" icon="el-icon-delete" @click="resetQueryInfo" style="width: 120px">重置</el-button>
          <el-button type="success" icon="el-icon-edit" @click="addArticle" style="width: 120px">
            添加文章
          </el-button>
        </el-col>
      </el-row>
      <!--文章列表区域-->
      <el-table :data="articleList" stripe border>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="作者" prop="author"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="更新时间" prop="updateTime"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!--修改-->
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="editArticle(scope.row.id)">修改
            </el-button>
            <!--删除-->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeArticleById(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: right"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.pageNo"
                     :page-sizes="[10, 15, 20, 25]"
                     :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ArticleSystem",

  created() {
    this.getArticleList()
  },

  data() {
    return {
      //总条数
      total: 0,
      //搜索条件对象
      queryInfo: {
        title: '',
        level: '',
        pageNo: 1,
        pageSize: 10
      },
      //文章列表数据
      articleList: [],
      //控制添加文章对话框的展示与隐藏
      addDialogVisible: false,
      //推荐等级选项 TODO 字典
      options: [{
        value: 1,
        label: '一级推荐'
      }, {
        value: 2,
        label: '二级推荐'
      }, {
        value: 3,
        label: '三级推荐'
      }, {
        value: 4,
        label: '四级推荐'
      }]
    }
  },
  methods: {
    //获取文章列表
    async getArticleList() {
      const {data: res} = await this.$http.get("/system-api/article", {params: this.queryInfo})
      if (res.code !== 200) return this.$message.error(res.result.message)
      this.articleList = res.result.records
      this.total = res.result.total
    },
    //添加文章跳转至添加页面
    addArticle() {
      this.$router.push('/article/add')
    },
    //修改文章
    editArticle(id) {
      this.$router.push('/article/' + id + '/edit')
    },
    //重置搜索
    resetQueryInfo() {
      this.queryInfo = {
        title: '',
        level: '',
        pageNo: 1,
        pageSize: 10
      }
      this.getArticleList()
    },
    handleSizeChange(value) {
      this.queryInfo.pageSize = value
      this.getArticleList()
    },
    handleCurrentChange(value) {
      this.queryInfo.pageNo = value
      this.getArticleList()
    },
    async removeArticleById(id) {
      const confirmResult =
          await this.$confirm('此操作将永久删除该文章, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info("已经取消了删除")
      }
      const {data: res} = await this.$http.delete("/system-api/article/" + id)
      if (res.code !== 200) {
        return this.$message.error("删除失败")
      }
      this.$message.success("删除成功")
      //刷新列表
      await this.getArticleList()
    }
  }

}
</script>

<style lang="less" scoped>
.el-table {
  margin-top: 15px;
  font-size: 12px;
}
</style>
