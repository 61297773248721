<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>普通用户</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片视图区域-->
    <el-card>
      <!--搜索框与添加区域-->
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入内容" v-model="queryInfo.userName" :clearable="true" @clear="getUserList">
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible=true">添加用户</el-button>
        </el-col>
      </el-row>
      <!--列表区域-->
      <el-table :data="userList"
                border stripe
                :cell-style="{'text-align':'center'}"
                :header-cell-style="{'text-align':'center'}">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="用户名" prop="userName"></el-table-column>
        <el-table-column label="昵称" prop="nickName"></el-table-column>
        <el-table-column label="手机号" prop="phone"></el-table-column>
        <el-table-column label="邮箱" prop="email"></el-table-column>
        <el-table-column label="角色名称" prop="description"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="更新时间" prop="updateTime"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="2"
              @change="userStatusChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="200px">
          <template slot-scope="scope">
            <!--修改-->
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">修改
            </el-button>
            <!--删除-->
            <el-button type="danger" icon="el-icon-delete" size="mini"
                       @click="removeUserById(scope.row.id)">删除
            </el-button>
            <!--分配角色-->
            <el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-setting" size="mini" @click="setRole(scope.row)">分配角色</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[1, 2, 10, 15]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="this.total"
        style="text-align: right">
      </el-pagination>
      <!--添加用户的对话框-->
      <el-dialog
        title="添加用户"
        :visible.sync="addDialogVisible"
        width="50%"
        @close="addDialogClosed">
        <!--内容主题区域-->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="70px">
          <el-form-item label="用户名" prop="userName">
            <el-input v-model="addForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="addForm.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="addForm.email"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="addForm.phone"></el-input>
          </el-form-item>
        </el-form>
        <!--底部区域-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addUser">确 定
          </el-button>
        </span>
      </el-dialog>
      <!--修改用户的对话框-->
      <el-dialog
        title="修改用户"
        :visible.sync="editDialogVisible"
        width="50%"
        @close="editDialogClosed">
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="70px">
          <el-form-item label="用户名">
            <el-input v-model="editForm.userName" disabled></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="editForm.email" disabled></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="editForm.phone" disabled></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="nickName">
            <el-input v-model="editForm.nickName"></el-input>
          </el-form-item>
          <el-form-item label="简介" prop="info">
            <el-input v-model="editForm.info"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="editUserInfo">确 定</el-button>
          </span>
      </el-dialog>

      <!--分配角色的对话框-->
      <el-dialog
        title="提示"
        :visible.sync="setRoleDialogVisible"
        width="50%" @close="setRoleDialogClosed">
        <div>
          <p>当前的用户:&nbsp&nbsp&nbsp&nbsp{{userInfo.userName}}</p>
          <p>当前的角色:&nbsp&nbsp&nbsp&nbsp{{userInfo.description}}</p>
          <p>分配新角色：
            <el-select v-model="selectRoleId" placeholder="请选择">
              <el-option
                v-for="item in rolesList"
                :key="item.id"
                :label="item.description"
                :value="item.id">
              </el-option>
            </el-select>
          </p>

        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="setRoleDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveRoleInfo">确 定</el-button>
        </span>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      //验证邮箱的规则
      const checkEmail = (rule, value, cb) => {
        //验证邮箱的正则表达式
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
        if (regEmail.test(value)) {
          return cb()
        }
        cb(new Error('请输入合法的邮箱'))

      };
      const checkPhone = (rule, value, cb) => {
        //验证手机号的正则表达式
        const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
        if (regMobile.test(value)) {
          return cb()
        }
        cb(new Error('请输入合法的手机号'))
      };
      return {
        queryInfo: {
          userName: '',
          //当前页
          pageNo: 1,
          //当前每页显示多少条数据
          pageSize: 10
        },
        userList: [],
        total: 10,
        //控制添加用户对话框的显示与隐藏
        addDialogVisible: false,
        //添加用户的表单数据
        addForm: {
          userName: '',
          password: '',
          email: '',
          phone: ''
        },
        //添加表单的验证规则对象
        addFormRules: {
          userName: [
            {required: true, message: '请输入用户名', trigger: 'blur'},
            {min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '请输入密码', trigger: 'blur'},
            {min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur'}
          ],
          email: [
            {required: true, message: '请输入邮箱', trigger: 'blur'},
            // {min: 5, max: 35, message: '长度在 5 到 35 个字符', trigger: 'blur'},
            {validator: checkEmail, trigger: 'blur'}
          ],
          phone: [
            {validator: checkPhone, trigger: 'blur'}
          ]
        },
        //控制修改用户对话框的显示与隐藏
        editDialogVisible: false,
        //查询到的用户信息对象
        editForm: {},
        //修改表单的验证规则对象
        editFormRules: {
          email: [
            {required: true, message: '请输入邮箱', trigger: 'blur'},
            // {min: 5, max: 35, message: '长度在 5 到 35 个字符', trigger: 'blur'},
            {validator: checkEmail, trigger: 'blur'}
          ],
          phone: [
            {validator: checkPhone, trigger: 'blur'}
          ],
          nickName: [
            {required: true, message: '请输入昵称', trigger: 'blur'},
            {min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur'}
          ],
          info: [
            {required: true, message: '请输入个人简介', trigger: 'blur'},
            {min: 3, max: 55, message: '长度在 3 到 55 个字符', trigger: 'blur'}
          ]
        },
        setRoleDialogVisible: false,
        //需要被分配角色的用户信息
        userInfo: {},
        //所有角色的数据列表
        rolesList: [],
        //已选中的角色id值
        selectRoleId: ''
      }
    },
    name: "User",
    created() {
      this.getUserList()
    },
    methods: {
      async getUserList() {
        const {data: res} = await this.$http.get("/system-api/user", {params: this.queryInfo})
        if (res.code !== 200) return this.$message.error("获取失败");
        this.userList = res.result.records;
        this.total = res.result.total;
      },
      //监听pagesize改变
      handleSizeChange(newSize) {
        this.queryInfo.pageSize = newSize
        this.getUserList()
      },
      //监听页码值改变
      handleCurrentChange(newPage) {
        //console.log(newPage)
        this.queryInfo.pageNo = newPage
        this.getUserList()
      },
      //监听switch开关状态的改变
      async userStatusChanged(userInfo) {
        //console.log(userInfo)
        const {data: res} = await this.$http.put(`/system-api/user/${userInfo.id}/${userInfo.status}`)
        if (res.code !== 200) {
          userInfo.status = !userInfo.status
          return this.$message.error("更新用户状态失败")
        }
        this.$message.success("更新用户状态成功")
      },
      //监听添加用户对话框的关闭事件
      addDialogClosed() {
        this.$refs.addFormRef.resetFields()
      },
      //点击确定按钮添加新用户
      addUser() {
        this.$refs.addFormRef.validate(async valid => {
          if (!valid) return
          //可以发起添加用户的网路请求
          const {data: res} = await this.$http.post(`/system-api/user`, this.addForm)
          if (res.code !== 200) {
            this.$message.error(res.message)
          }
          this.$message.success(res.message)
          //隐藏添加用户的对话框
          this.addDialogVisible = false
          //重新获取用户列表数据
          await this.getUserList()
        })
      },
      //展示编辑用户的对话框
      async showEditDialog(id) {
        const {data: res} = await this.$http.get('/system-api/user/' + id)
        if (res.code !== 200) {
          return this.$message.error("查询用户信息失败");
        }
        this.editForm = res.result
        this.editDialogVisible = true;
      },
      //监听修改用户对话框的关闭事件
      editDialogClosed() {
        this.$refs.editFormRef.resetFields()
      },
      //修改用户信息
      editUserInfo() {
        this.$refs.editFormRef.validate(async valid => {
          if (!valid) return
          //发起修改用户信息的数据请求
          const {data: res} = await this.$http.put("/system-api/user/", this.editForm)
          if (res.code !== 200) {
            return this.$message.error("修改失败")
          }
          //关闭对话框
          this.editDialogVisible = false
          //刷新用户列表
          await this.getUserList()
          //提示修改成功
          return this.$message.success("修改成功")
        })
      },
      //根据id删除用户信息
      async removeUserById(id) {
        const confirmResult =
          await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).catch(err => err)
        //如果用户确认删除，则返回值为字符串confirm
        //如果用户取消了删除，则返回值为字符串cancel
        //console.log(confirmResult)
        if (confirmResult !== 'confirm') {
          return this.$message.info("已经取消了删除")
        }
        const {data: res} = await this.$http.delete("/system-api/user/" + id)
        if (res.code !== 200) {
          return this.$message.error("删除失败")
        }
        this.$message.success("删除成功")
        //刷新列表
        await this.getUserList()
      },
      //展示分配角色的对话框
      async setRole(userInfo) {
        this.userInfo = userInfo
        //在展示对话框之前，获取所有角色的列表
        const {data: res} = await this.$http.get('/system-api/role')
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.rolesList = res.result
        this.setRoleDialogVisible = true;
      },
      //点击按钮，分配角色
      async saveRoleInfo() {
        if (!this.selectRoleId) {
          return this.$message.error('请选择要分配的角色')
        }
        const vo = {
          userId: this.userInfo.id,
          roleId: this.selectRoleId
        }
        const {data: res} = await this.$http.post('/system-api/user/role', vo)
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.$message.success(res.message)
        await this.getUserList()
        this.setRoleDialogVisible = false
      },
      //监听分配角色对话框的关闭事件
      setRoleDialogClosed() {
        this.selectRoleId = ''
        this.userInfo = {}
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-table {
    margin-top: 15px;
    font-size: 12px;
  }
</style>
