<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片视图-->
    <el-card>
      <!--添加角色按钮区域-->
      <el-row>
        <el-col>
          <el-button type="primary">添加角色</el-button>
          <!--角色列表区域-->
          <el-table :data="roleList" border stripe :cell-style="{'text-align':'center'}"
                    :header-cell-style="{'text-align':'center'}">
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-row :class="['bdBottom',i1===0 ? 'bdTop':'','vCenter']" v-for="(item1,i1) in scope.row.rights"
                        :key="item1.id">
                  <!--渲染一级权限-->
                  <el-col :span="6">
                    <el-tag closable @close="removeRightById(scope.row,item1.id)">{{item1.name}}</el-tag>
                    <i class="el-icon-caret-right"></i>
                  </el-col>
                  <!--渲染二级与三级-->
                  <el-col :span="18">
                    <!--通过for 嵌套渲染二级权限-->
                    <el-row :class="[i2===0?'':'bdTop','vCenter']" v-for="(item2,i2) in item1.children" :key="item2.id">
                      <el-col :span="6">
                        <el-tag type="success" closable @close="removeRightById(scope.row,item2.id)">{{item2.name}}
                        </el-tag>
                        <i class="el-icon-caret-right"></i>
                      </el-col>
                      <el-col :span="18">
                        <el-tag v-for="(item3) in item2.children" :key="item3.id" type="warning" closable
                                @close="removeRightById(scope.row,item3.id)">
                          {{item3.name}}
                        </el-tag>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <!--索引列-->
            <el-table-column type="index"></el-table-column>
            <el-table-column label="角色名称" prop="roleName"></el-table-column>
            <el-table-column label="角色描述" prop="description"></el-table-column>
            <el-table-column label="创建时间" prop="createTime"></el-table-column>
            <el-table-column label="更新时间" prop="updateTime"></el-table-column>
            <el-table-column label="操作" width="400px">
              <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" size="mini">编辑</el-button>
                <el-button type="danger" icon="el-icon-delete" size="mini">删除</el-button>
                <el-button type="warning" icon="el-icon-setting" size="mini" @click="showSetRightsDialog(scope.row)">
                  分配权限
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
    <!--分配权限对话框-->
    <el-dialog
      title="分配权限"
      :visible.sync="setRightsDialogVisible"
      width="50%" @close="setRightDialogClose">
      <!--树形控件-->
      <!--
      node-key 绑定权限id
      default-expand-all 默认展开所有节点
      -->
      <el-tree :data="rightList" :props="treeProps" show-checkbox node-key="id" default-expand-all
               :default-checked-keys="defKeys" ref="treeRef"></el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRightsDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="allotRights">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "Roles",
    //1.定义私有数据节点
    data() {
      return {
        //定义所有角色列表数据
        roleList: [],
        //控制分配权限对话框的显示与隐藏
        setRightsDialogVisible: false,
        //所有权限的数据
        rightList: [],
        //树形控件的属性2绑定对象
        treeProps: {
          label: 'name',
          children: 'children'
        },
        //默认选中的节点id值数组
        defKeys: [],
        //当前即将分配角色权限的角色id
        roleId: ''
      }
    },
    //2.定义个生命周期函数
    created() {
      //获取所有角色的列表数据
      this.getRolesList()
    },
    //3.定义方法
    methods: {
      async getRolesList() {
        const {data: res} = await this.$http.get("/system-api/role")
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.roleList = res.result;
      },
      //根据id删除对应的权限
      async removeRightById(role, pid) {
        //弹框提示用户是否删除
        const confirmResult = await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err)
        if (confirmResult !== 'confirm') {
          return this.$message.info("取消了删除")
        }
        const {data: res} = await this.$http.delete('/system-api/role/permission', {
          params: {
            roleId: role.id,
            perId: pid
          }
        })
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        //刷新列表
        //await this.getRolesList()
        //将返回的新权限数据赋值给当前的role
        role.children = res.result
      },
      //展示分配权限的对话框
      async showSetRightsDialog(role) {
        this.roleId = role.id;
        //获取所有权限数据
        const {data: res} = await this.$http.get('/system-api/permission?type=tree')
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        //把获取到的权限数据保存到data中
        this.rightList = res.result
        //递归获取三级节点的id
        this.getLeafKeys(role.rights, this.defKeys)
        this.setRightsDialogVisible = true;
      },
      //通过递归的形式，获取角色下所有三级权限的id,并保存到 defKeys 数组中
      getLeafKeys(rights, arr) {
        for (let i = 0; i < rights.length; i++) {
          const right = rights[i];
          //如果当前节点不保护children属性，则是三级节点
          if (right.children.length === 0) {
            debugger
            return arr.push(rights.id);
          }
          right.children.forEach(item =>
            this.setLeafKeys(item, arr))
        }
      },
      setLeafKeys(right, arr) {
        if (right.children.length === 0) {
          return arr.push(right.id)
        }
        right.children.forEach(item => {
          this.setLeafKeys(item, arr)
        })
      },
      //监听分配权限对话框的关闭事件
      setRightDialogClose() {
        this.defKeys = []
      },
      //点击为角色授权
      async allotRights() {
        const keys = [
          ...this.$refs.treeRef.getCheckedKeys(),
          ...this.$refs.treeRef.getHalfCheckedKeys()
        ]
        const idStr = keys.join(',')
        const vo = {
          roleId: this.roleId,
          perId: idStr
        }
        const {data: res} = await this.$http.post(`/system-api/role/permission`, vo)
        if (res.code !== 200) {
          return this.$message.error(res.message)
        }
        this.$message.success(res.message)
        await this.getRolesList()
        this.setRightsDialogVisible = false
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-tag {
    margin: 7px;
  }

  .bdTop {
    border-top: 1px solid #eeeeee;
  }

  .bdBottom {
    border-bottom: 1px solid #eeeeee;
  }

  .vCenter {
    display: flex;
    align-items: center;
  }
</style>
