<template>
  <div>
    <!--面包屑导航区-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>图片管理</el-breadcrumb-item>
      <el-breadcrumb-item>分类列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input placeholder="请输入内容" v-model="queryInfo.name" :clearable="true" @clear="getPictureCategoryList">
            <el-button slot="append" icon="el-icon-search" @click="getPictureCategoryList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="showAddDialog">添加分类</el-button>
        </el-col>
      </el-row>
    </el-card>
    <!--列表区域-->
    <el-table
      border
      stripe
      :data="pictureCategorys"
      style="width: 100%"
      :cell-style="{'text-align':'center'}"
      :header-cell-style="{'text-align':'center'}">
      <el-table-column type="index"></el-table-column>
      <el-table-column prop="picId" label="封面">
        <template slot-scope="scope">
          <el-image :src="scope.row.cover">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="categoryName" label="分类名称"></el-table-column>
      <el-table-column prop="categoryCode" label="分类编码"></el-table-column>
      <el-table-column prop="isShow" label="云相册">
        <template slot-scope="scope">
          <el-button v-if="scope.row.isShow==='0'" type="danger" size="mini">隐藏</el-button>
          <el-button v-else type="success" size="mini">展示</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间"></el-table-column>
      <el-table-column label="操作" min-width="150px">
        <template slot-scope="scope">
          <!--图片列表-->
          <el-button type="success" icon="el-icon-search" size="mini" @click="toPictureList(scope.row.id)">
            图片
          </el-button>
          <!--修改-->
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">
            修改
          </el-button>
          <!--删除-->
          <el-button type="danger" icon="el-icon-delete" size="mini"
                     @click="removePictureCategoryByCategoryId(scope.row.id)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--新增对话框-->
    <el-dialog
      title="添加分类"
      :visible.sync="isVisible"
      width="30%"
      @close="addPictureCategoryDialogClosed">
      <el-form :model="formData" :rules="formDataRules" ref="formDataRef"
               label-width="100px" class="demo-ruleForm">
        <el-form-item label="分类名称" prop="categoryName" label-width="120px">
          <el-input v-model="formData.categoryName"></el-input>
        </el-form-item>
        <el-form-item label="分类编码" prop="categoryCode" label-width="120px">
          <el-input v-model="formData.categoryCode" :disabled="this.categoryId !== null"></el-input>
        </el-form-item>
        <el-form-item label="云相册" prop="isShow" label-width="120px">
          <template>
            <el-radio v-model="formData.isShow" :label="'0'">隐藏</el-radio>
            <el-radio v-model="formData.isShow" :label="'1'">显示</el-radio>
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="isVisible = false">取 消</el-button>
          <el-button type="primary" @click="addPictureCategory">确 定</el-button>
      </span>
    </el-dialog>
    <!--分页-->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNo"
      :page-sizes="[1, 2, 5, 10]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="this.total">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    name: "PictureCategory",
    data() {
      return {
        //查询对象
        queryInfo: {
          categoryName: '',
          pageNo: 1,
          pageSize: 10
        },
        //类型表单数据
        pictureCategorys: [],

        //新增or编辑
        formData: {
          //新增图片分类表单对象
          categoryName: '',
          categoryCode: '',
          isShow: ''
        },

        //新增类型校验规则
        formDataRules: {
          name: [{required: true, message: '请输入分类名称', trigger: 'blur'}]
        },
        //控制新增对话框的显示与隐藏
        isVisible: false,
        //总条数
        total: 0,
        categoryId: null,
      }
    },
    mounted() {
      this.getPictureCategoryList()
    },
    methods: {
      //获取分类列表
      getPictureCategoryList() {
        this.$http.get('/system-api/picture/category', {params: this.queryInfo}).then(res => {
          this.pictureCategorys = res.data.result.records
          this.total = res.data.result.total
        })
      },
      //点击显示编辑对话框
      showEditDialog(id) {
        this.$http.get('/system-api/picture/category/' + id).then(res => {
          //先获取数据
          this.formData = {}
          this.formData = res.data.result
          // 强制重新进行双向绑定
          this.$forceUpdate()
          this.categoryId = res.data.result.id
          this.isVisible = true
        })
      },
      //显示新增对话框
      showAddDialog() {
        this.isVisible = true
      },
      //点击新增图片分类
      addPictureCategory() {
        //判断表单是否通过
        this.$refs.formDataRef.validate(valid => {
          if (!valid) return
          //新增
          if (!this.categoryId) {
            this.$http.post('/system-api/picture/category', this.formData).then(res => {
              if (res.data.code !== 201) {
                return this.$message.error(res.data.message)
              }
              this.$message.success(res.data.message)
              this.$refs.formDataRef.resetFields()
              this.isVisible = false
              this.getPictureCategoryList()
            });
          }
          //编辑
          else {
            console.log('formData', this.formData)
            this.$http.put('/system-api/picture/category', this.formData).then(res => {
              if (res.data.code !== 200) {
                return this.$message.error(res.data.message)
              }
              this.$message.success(res.data.message)
              this.$refs.formDataRef.resetFields()
              this.isVisible = false
              this.getPictureCategoryList()
            })
          }
        })
      },
      //监听表单关闭
      addPictureCategoryDialogClosed() {
        this.$refs.formDataRef.resetFields()
        this.categoryId = null
        //数据恢复初始
        this.formData = {
          //新增图片分类表单对象
          categoryName: '',
          categoryCode: '',
          isShow: ''
        }
      },
      //点击删除分类并提示删除分类下的所有图片
      removePictureCategoryByCategoryId(categoryId) {
        this.$confirm('此操作会将该分类下的图片移到默认分组，是否继续', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch(err => err).then(res => {
          if (res !== 'confirm') {
            return this.$message.info('已经取消了删除');
          }
          //执行删除
          this.$http.delete('/system-api/picture/category/' + categoryId).then(res => {
            if (res.data.code !== 200) {
              return this.$message.error(res.data.message)
            }
            this.$message.success(res.data.message)
            this.getPictureCategoryList()
          })
        })

      },
      //监听每页条数变化
      handleSizeChange(newSize) {
        this.queryInfo.pageSize = newSize
        this.getPictureCategoryList()
      },
      //监听页码值改变
      handleCurrentChange(newPageNo) {
        this.queryInfo.pageNo = newPageNo
        this.getPictureCategoryList()
      },
      //跳转到图片列表页码
      toPictureList(id) {
        this.$router.push('/picture/list/' + id)
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-table {
    margin-top: 10px;
    font-size: 12px;
  }

  .el-image {
    width: 130px;
    height: 70px;
  }
</style>
